/**
 *
 * Application container
 *
 * This uiComponent is the framework around the entire application, and should only
 * contain code that should be seem on all pages.(e.g. navigation bar, topbar and etc.)
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Import routes
import routes from '../../configure/routes';
import {Link} from "react-router-dom";

// Style
import './index.css';
import {logo,giteeLogo} from '../../assets';

// Class Framework, basic uiComponent for application
class Framework extends Component {

    /**
     * constructor function
     * @param {*} props
     */
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className={"TopBar"}>
                    <Link className={"focus-ring-link"} to={"/"}>
                        <img src={logo} className={"TopBar-logo"}/>
                    </Link>
                    <nav className={"TopBar-nav"}>
                        <Link className="TopBar-link" to="/">首页</Link>
                        <Link className="TopBar-link" to="/documents/">开发文档</Link>
                    </nav>
                </div>
                <main className={"MainLayout-main"}>
                    {routes}
                </main>
                <footer className="PageFooter bg-dark clearfix">
                    <div className="Container PageFooter-inner">
                        <div className="PageFooter-left">
                            <img src={logo} className={"BottomBar-logo"}/>
                        </div>
                        <div className="PageFooter-right"><p>该网站基于: 蜂巢科技 zkhoneycomb UI Kit 开发
                            </p></div>
                    </div>
                    <div className="Container PageFooter-inner">
                        <div className="PageFooter-left">
                            <div>

                            </div>
                        </div>
                        <div className="PageFooter-right"><p>2020, 蜂巢科技.</p></div>
                    </div>
                </footer>
            </div>
        )
    }

}

// Default props
Framework.defaultProps = {};

// Prop attributes types
Framework.propTypes = {};

/**
 * mapStateToProps is a function provided to pull data from the store when it changes,
 * and pass those values as props to your uiComponent.
 *
 * @param {*} state
 */
const mapStateToProps = state => ({});

/**
 * mapDispatchToProps is a function provided to make use of the store's dispatch function,
 * usually by creating pre-bound versions of action creators that will automatically
 * dispatch their actions as soon as they are called.
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => ({});

// Connect Switch to store to pass location down to each Routes.
// const ConnectedSwitch = connect(mapStateToProps)(Switch);

// Export Framework container
export default connect(mapStateToProps, mapDispatchToProps, null, {pure: false})(Framework);
