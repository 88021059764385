/**
 *
 * Application container
 *
 * This uiComponent is the framework around the entire application, and should only
 * contain code that should be seem on all pages.(e.g. navigation bar, topbar and etc.)
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from "lodash";
// UI framework
import {SidebarTab, Pane, Tab, Tablist, Paragraph} from 'evergreen-ui';

// Actions

// Selectors

// Style
import './index.css';
import {Heading, UnorderedList, ListItem} from "evergreen-ui";

// Class Framework, basic uiComponent for application
class TabComponent extends Component {

    /**
     * constructor function
     * @param {*} props
     */
    constructor(props) {
        super(props);
        this.state={
            selectedIndex: 0,
            tabs: ['选项卡1', '选项卡2', '选项卡3']
        };
    }

    render() {
        return (
            <section className={"MainLayout-contentRight"}>
                <div className={"Container-nextToSidebar"} style={{marginBottom: "120px"}}>
                    <div className={"Content"}>
                        <h2 className="heading h2">MetaGraph SDK 下载与配置</h2>
                        <p>Coming Soon...</p>
                    </div>
                </div>
            </section>
        )
    }

}

// Default props
TabComponent.defaultProps = {};

// Prop attributes types
TabComponent.propTypes = {};

/**
 * mapStateToProps is a function provided to pull data from the store when it changes,
 * and pass those values as props to your uiComponent.
 *
 * @param {*} state
 */
const mapStateToProps = state => ({
});

/**
 * mapDispatchToProps is a function provided to make use of the store's dispatch function,
 * usually by creating pre-bound versions of action creators that will automatically
 * dispatch their actions as soon as they are called.
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => ({
});

// Export Checkout container
export default connect(mapStateToProps, mapDispatchToProps, null, {pure: false})(TabComponent);
