export const logo = require("./images/logo.png");
export const illustration = require("./images/illustration.png");
export const redashImg = require("./images/redash.jpeg");
export const placeholderImage = require("./images/placeholder-image.png");
export const metagraphOnlineDocument = require("./images/metagraphOnlineDocument.PNG");
export const reactReduxImg = require("./images/reactredux.png");
export const uiSeedImg = require("./images/uiseed.png");
export const giteeLogo = require("./images/gitee-logo.svg");
export const checkstyleLogo = require("./images/header-checkstyle-logo.png");
export const checkstyle01 = require("./images/checkstyle01.png");
export const checkstyle02 = require("./images/checkstyle02.png");
export const checkstyle03 = require("./images/checkstyle03.png");
export const checkstyle04 = require("./images/checkstyle04.png");
export const checkstyle05 = require("./images/checkstyle05.png");
export const checkstyle06 = require("./images/checkstyle06.png");