/**
 *
 * Application container
 *
 * This uiComponent is the framework around the entire application, and should only
 * contain code that should be seem on all pages.(e.g. navigation bar, topbar and etc.)
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from "lodash";
// UI framework
import {Button, Pane} from 'evergreen-ui';

// Actions

// Selectors

// Style
import './index.css';
import {Heading, UnorderedList, ListItem} from "evergreen-ui";
import {checkstyleLogo,checkstyle01,checkstyle02,checkstyle03,checkstyle04,checkstyle05,checkstyle06} from "../../../assets";

// Class Framework, basic uiComponent for application
class ButtonComponent extends Component {

    /**
     * constructor function
     * @param {*} props
     */
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <section className={"MainLayout-contentRight"}>
                <div className={"Container-nextToSidebar"} style={{marginBottom: "120px"}}>
                    <div className={"Content"}>
                        <h1><img src={checkstyleLogo} width={"200rx"} alt={""}/> </h1>
                        <h2>介绍</h2>
                        <p className="p">
                            Checkstyle是在MetaGraph开发中的一种静态代码分析工具，用来检查metaGraph项目中Java源代码是否符合编码规则.
                        </p>
                        <h2>checkstyle Eclipse开发环境配置</h2>
                        <p>
                            打开Eclipse, 进入Window->Preferences。左侧菜单栏选择Checkstyle。
                            <img src={checkstyle01} width={"100%"} alt={""}/>
                            点击New按钮打开新建窗口.
                            <img src={checkstyle02} width={"100%"} alt={""}/>
                            <ul className={"ul"}>
                                <li className={"li"}>Type：Remote Configuration</li>
                                <li className={"li"}>Name：MetaGraph checkstyle</li>
                                <li className={"li"} style={{color:"#d63447"}}>Location：http://metagraph-doc.chinambse.com/checkstyle.xml</li>
                            </ul>
                            确认，并将新建的MetaGraph Checkstyle设置为默认.
                            <img src={checkstyle03} width={"100%"} alt={""}/>
                        </p>
                        <h2>激活checkstyle设置</h2>
                        <p>
                            <img src={checkstyle04} width={"100%"} alt={""}/>
                            在Eclipse选中要激活checkstyle的项目，右键打开菜单并选中Checkstyle选项，在弹出的二级菜单中选择"Activate Checkstyle"来激活checkstyle.
                            选择激活后Eclipse会提示是否需要立刻进行checkstyle检查，选择是。<br/>
                            或者可以之后再选择"Check Code with Checkstyle"对项目进行代码检查,checkstyle激活以后会自动跟踪检查代码并提示错误。
                        </p>
                        <h2>例子</h2>
                        以下例子中，由于类PluginImages用来提供静态功能性函数，并且定义为final，系统识别为该类为Utility Class，则该类无需public的构造函数和默认的public构造函数，
                        所以报错："Utility classes should not have a public or default constructor."
                        <img src={checkstyle05} width={"100%"} alt={""}/>
                        修改代码添加私有构造函数解决该报错。
                        <img src={checkstyle06} width={"100%"} alt={""}/>
                    </div>
                </div>
            </section>
        )
    }

}

// Default props
ButtonComponent.defaultProps = {};

// Prop attributes types
ButtonComponent.propTypes = {};

/**
 * mapStateToProps is a function provided to pull data from the store when it changes,
 * and pass those values as props to your uiComponent.
 *
 * @param {*} state
 */
const mapStateToProps = state => ({
});

/**
 * mapDispatchToProps is a function provided to make use of the store's dispatch function,
 * usually by creating pre-bound versions of action creators that will automatically
 * dispatch their actions as soon as they are called.
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => ({
});

// Export Checkout container
export default connect(mapStateToProps, mapDispatchToProps, null, {pure: false})(ButtonComponent);
