/**
 *
 * Application container
 *
 * This uiComponent is the framework around the entire application, and should only
 * contain code that should be seem on all pages.(e.g. navigation bar, topbar and etc.)
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {push} from "connected-react-router";
import {Link, NavLink,Route,Switch} from "react-router-dom";

import _ from "lodash";
// UI framework
import {Tablist, SidebarTab} from 'evergreen-ui';
import TabComponent from './tab';
import ButtonComponent from './button';
// Actions

// Selectors
import {allComponentsSelector} from './selectors';

// Style
import './index.css';
import {checkstyleLogo} from "../../assets";

// Class Framework, basic uiComponent for application
class UiComponent extends Component {

    /**
     * constructor function
     * @param {*} props
     */
    constructor(props) {
        super(props);
    }

    render() {
        const {navTo} = this.props;
        const {allComponents} = this.props;
        return (
            <div className={"MainLayout-main"}>
                <div className={"MainLayout-content"}>
                    <Switch>
                        <Route exact path="/documents/sdk" component={TabComponent}/>
                        <Route exact path="/documents/checkstyle" component={ButtonComponent}/>
                    </Switch>
                </div>
                <aside className="MainLayout-sidebar Sidebar">
                    <h3 className="Overview-groupTitle">开发文档</h3>
                    <Tablist>
                        {
                            allComponents.map(component =>
                                <Link key={component} to={"/documents/"+_.toLower(component.get("id"))} onClick={()=> {}}>
                                    <SidebarTab
                                        key={component.get("id")}
                                        id={component.get("id")}
                                        isSelected={false}
                                    >
                                        {component.get("name")}
                                    </SidebarTab>
                                </Link>
                            )
                        }
                    </Tablist>
                </aside>
            </div>
        )
    }

}

// Default props
UiComponent.defaultProps = {};

// Prop attributes types
UiComponent.propTypes = {};

/**
 * mapStateToProps is a function provided to pull data from the store when it changes,
 * and pass those values as props to your uiComponent.
 *
 * @param {*} state
 */
const mapStateToProps = state => ({
    allComponents: allComponentsSelector(state)
});

/**
 * mapDispatchToProps is a function provided to make use of the store's dispatch function,
 * usually by creating pre-bound versions of action creators that will automatically
 * dispatch their actions as soon as they are called.
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => ({
    navTo: location => dispatch(push(location))
});

// Export Checkout container
export default connect(mapStateToProps, mapDispatchToProps, null, {pure: false})(UiComponent);
