/**
 *
 * home page
 *
 * This uiComponent is the home page not found page.
 */

import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {Link} from "react-router-dom";


// UI framework
import {illustration, logo, redashImg, uiSeedImg, metagraphOnlineDocument} from '../../assets';
import {Button} from 'evergreen-ui';
// Style
import "./index.css";


// Class Home, basic uiComponent for application
class Home extends Component {
  /**
   * constructor function
   * @param {*} props
   */
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className={"MainLayout-content"}>
        <section className={"Hero"}>
          <div className={"Hero-inner"}>
            <div className={"Hero-left"}>
              <img src={logo} style={{width: "9rem", marginBottom: "1rem"}}/>
              <h1>中科蜂巢MetaGraph多架构建模工具开发文档</h1>
              <p>
                MetaGraph介绍，SDK下载以及配置，开发环境搭建以及配置文档.
              </p>
              <div>
                <Link style={{marginRight:'1rem'}} to={"/documents/sdk"}>
                  <Button appearance="primary" height={40} >查看开发文档</Button>
                </Link>
                <a style={{marginRight:'1rem'}} href="">
                  <Button appearance="primary" intent={"danger"} height={40} iconBefore={"chat"}>获取更多信息</Button>
                </a>
              </div>
            </div>
            <div className={"Hero-right"}>
              <img src={illustration} height="396"/>
            </div>
          </div>
        </section>
        <section className="Features bg-green color-white clearfix">
          <div className="Container Features-grid">
            <div className="Feature">
              <h2 className="h3 Feature-title">快速开发</h2>
              <p className="Feature-description">蜂巢zkhoneycomb UI系统提供一个空的Seed项目用于快速开发生成适用于生产环境的前端程序.
              </p>
            </div>
            <div className="Feature">
              <h2 className="h3 Feature-title">丰富，统一的用户界面</h2>
              <p className="Feature-description">
                使用蜂巢zkhoneycomb UI内置的多种前端模块以及丰富的API，让产品拥有统一的用户界面和风格。
              </p>
            </div>
            <div className="Feature">
              <h2 className="h3 Feature-title">流畅，高性能Web框架</h2>
              <p className="Feature-description">基于React & Redux，该UI系统页面切换流畅，数据流清晰快速.</p>
            </div>
          </div>
        </section>
        <section className="Media bg-tint2 clearfix">
          <div className="Media-inner Container">
            <header style={{textAlign: 'center', marginTop: '64px'}}>
              <svg width="75px" height="75px" viewBox="0 0 64 64">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <circle fill-opacity="0.1" fill="#47B881" fill-rule="nonzero" cx="32" cy="32" r="32"></circle>
                  <path d="M36.2 24.154l-14.4 5.4v4.892l14.4 5.4V24.154zm.52 17.595l-16-6A.8.8 0 0 1 20.2 35v-6a.8.8 0 0 1 .52-.75l16-6a.8.8 0 0 1 1.08.75v18a.8.8 0 0 1-1.08.75zm3.486-13.56a.8.8 0 0 1-.812-1.379l1.7-1a.8.8 0 0 1 .812 1.38l-1.7 1zm-.812 9a.8.8 0 0 1 .812-1.379l1.7 1a.8.8 0 0 1-.812 1.38l-1.7-1zM41 32.8a.8.8 0 1 1 0-1.6h2a.8.8 0 1 1 0 1.6h-2zM28.2 38a.8.8 0 1 1 1.6 0v3a.8.8 0 0 1-.932.79l-6-1A.8.8 0 0 1 22.2 40v-4.2a.8.8 0 1 1 1.6 0v3.522l4.4.734V38zm-4-10.5a.8.8 0 1 1 1.6 0v9a.8.8 0 1 1-1.6 0v-9z" fill="#47B881" fill-rule="nonzero"></path>
                </g>
              </svg>
              <h2 className="h2" style={{marginTop: '32px'}}>基于zkhoneycomb UI的产品</h2>
            </header>
            <div className="Media-grid">
              <a href="http://datavis.chinambse.com/" className="MediaItem">
                <figure><img src={redashImg} className="MediaItem-image" /></figure>
                <div className="MediaItem-content">
                  <h3 className="MediaItem-title">
                    DataVis <br/> 蜂巢科技数据可视化平台
                  </h3>
                  <p className="MediaItem-published">6月, 2019年</p>
                </div>
              </a>
              <a href="" className="MediaItem">
                <figure><img src={uiSeedImg} className="MediaItem-image" /></figure>
                <div className="MediaItem-content">
                  <h3 className="MediaItem-title">
                    中科蜂巢前端Seed项目:<br/>
                    基于React的UI Kit，用来快速构建风格统一的React程序。
                  </h3>
                  <p className="MediaItem-published">5月, 2019年</p>
                </div>
              </a>
              <a href="" className="MediaItem">
                <figure><img src={metagraphOnlineDocument} className="MediaItem-image" /></figure>
                <div className="MediaItem-content">
                  <h3 className="MediaItem-title">
                    metaGraph在线开发文档
                  </h3>
                  <p className="MediaItem-published">3月, 2020年</p>
                </div>
              </a>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

// Default props
Home.defaultProps = {};

// Prop attributes types
Home.propTypes = {};

/**
 * mapStateToProps is a function provided to pull data from the store when it changes,
 * and pass those values as props to your uiComponent.
 *
 * @param {*} state
 */
const mapStateToProps = state => ({});

/**
 * mapDispatchToProps is a function provided to make use of the store's dispatch function,
 * usually by creating pre-bound versions of action creators that will automatically
 * dispatch their actions as soon as they are called.
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = dispatch => ({});

// Export Home container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {pure: false}
)(Home);
