/**
 * appSelector
 *
 * Selector is a safe way to read immutable state from redux store.
 *
 * selector api and functions can be referenced from:
 * https://facebook.github.io/immutable-js/
 *
 */

import { createSelector } from "reselect";

import { fromJS } from "immutable";

export const uiComponentSelector = state =>
    state.getIn(["uiComponent"], fromJS({}));

export const allComponentsSelector = createSelector(
    uiComponentSelector,
    uiComponent => uiComponent.get("components", [])
);